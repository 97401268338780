import {Analytics} from "@vercel/analytics/react"
import {ThemeProvider} from "next-themes"
import Head from "next/head"
import "../styles/globals.css"

const isBrowser = typeof window != "undefined" && !!window.indexedDB

const authEmulatorHost = process.env.NEXT_PUBLIC_FIREBASE_AUTH_EMULATOR_HOST
const firestoreEmulatorHost = process.env.NEXT_PUBLIC_FIRESTORE_EMULATOR_HOST

function MyApp({Component, pageProps}: any) {
  return (
    <>
      <Head>
        <title>FocusTask</title>
        <meta
          name="viewport"
          content="width=device-width, user-scalable=no, viewport-fit=cover"
        />
      </Head>

      <ThemeProvider attribute="class">
        <Component {...pageProps} />
        <Analytics />
      </ThemeProvider>
    </>
  )
}

export default MyApp
